button.hamburger {
  outline: none !important;

  .hamburger-inner, 
  .hamburger-inner::after, 
  .hamburger-inner::before {
    background-color: $dark;
  }

  &.color-dark {
    .hamburger-inner, 
    .hamburger-inner::after, 
    .hamburger-inner::before {
      background-color: $dark;
    } 
  }

  &.color-light {
    .hamburger-inner, 
    .hamburger-inner::after, 
    .hamburger-inner::before {
      background-color: $light;
    } 
  }

  &.color-red {
    .hamburger-inner, 
    .hamburger-inner::after, 
    .hamburger-inner::before {
      background-color: $red;
    } 
  }
}