div.row.no-container-row {
  margin-right: 0;
  margin-left: 0;

  @media (max-width: $screen-xs-max) {
    &>div>div.neos-contentcollection {
      button {
        margin-bottom: 25px;
      }
    }
  }

}
