
.obis-site-slider {
  position: relative;
  height: 100%;

  .slider-contents {
    margin-bottom: 0;
    max-height: 100%;

    .slick-list {
      width: 100%;
      max-height: 100%;

      .slick-track {
        .slick-slide {
          @include slide-height-mixin();

          .obis-site-slider-slide {
            @include slide-height-mixin();
            @include dynamic-background-preconfig();

            position: relative;

            .slide-content-wrapper {
              z-index: 10;
              display: block;
              position: relative;
              width: 100%;
              height: 100%;

              .slide-inner {
                @include flexbox-container();

                width: 100%;
                height: 100%;

                & > .neos-contentcollection {
                  @include general-headline-styling;

                  @media (max-width: $screen-xs-max) {
                    h1 {
                      font-size: 3.5em;
                    }

                    h2 {
                      font-size: 3.0em;
                    }

                    h3 {
                      font-size: 2.5em;
                    }

                    h4 {
                      font-size: 2.0em;
                    }

                    h5 {
                      font-size: 1.5em;
                    }

                  }


                  min-width: 100px;
                }
              }
            }
          }
        }
      }
    }
  }

  .slider-controls {
    .slider-button {
      position: absolute;
      top: calc(50% - 21px);
      height: 42px;
      color: $light;

      background: none;
      border: none;
      outline: none !important;

      margin: 0;
      padding: 0;
      font-size: 40px;
      line-height: 40px;

      &.button-prev {
        left: 10px;
        text-shadow: 2px 1px 5px $black;
      }

      &.button-next {
        right: 10px;
        text-shadow: -2px 1px 5px $black;
      }
    }

    .slider-pagination {
      bottom: 15px;
      left: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 0;
      text-align: center;

      & > li {
        display: inline-block;
        margin: 0 2px;

        & > button {
          background-color: $white;
          border-radius: 50%;
          border: none;
          height: 12px;
          width: 12px;
          padding: 0;
          font-size: 0;
          outline: none !important;
          transition: background-color 0.2s ease;
          cursor: pointer;
        }

        &.slick-active {
          & > button {
            background-color: $red;
          }
        }
      }
    }
  }
}
