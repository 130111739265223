.obis-site-headline {
  margin-bottom: 15px;
  word-break: break-word;
  overflow: hidden;
  
  h1, h2, h3 {
    text-transform: uppercase;
    font-weight: bold;
  }

  .bar {
    height: 5px;
    width: 50px;
    background-color: $dark;
  }
  
  &.align-left {
    text-align: left;
  }

  &.align-center {
    text-align: center;

    .bar {
      margin: 0 auto;
    }
  }

  &.align-right {
    text-align: right;

    .bar {
      margin-left: auto;
    }
  }

  &.align-justify {
    text-align: justify;

    .bar {
      margin: 0 auto;
    }
  }

  &.color-light {
    color: $light;

    .bar {
      background-color: $light;
    }
  }

  &.color-dark {
    color: $dark;

    .bar {
      background-color: $dark;
    }
  }

  &.color-red {
    color: $red;

    .bar {
      background-color: $red;
    }
  }

  & > img {
    max-height: 50px;
    max-width: 50px;
    height: auto;
    width: auto;
  }
}