// Bootstrap
$icon-font-path: '../Fonts/bootstrap/';

@import 'bootstrap-sprockets';
@import 'bootstrap';

// Font Awesome
$fa-font-path: '../Fonts';
@import 'fontawesome';
@import 'regular';
@import 'solid';
@import 'brands';

// Bootstrap hidden classes workaround
@media (max-width: $screen-xs-max) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: $screen-xs-min) and (max-width: $screen-sm-max) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-lg-max) {
  .hidden-lg {
    display: none !important;
  }
}

@media (min-width: $screen-lg-min) {
  .hidden-xl {
    display: none !important;
  }
}

// Slick Carousel

$slick-font-path: "../Fonts/";
$slick-loader-path: "../Images/";
@import 'slick-theme.scss';

// CSS Hamburgers

$hamburger-padding-x: 5px;
$hamburger-padding-y: 11px;
$hamburger-layer-width: 35px;
$hamburger-layer-height: 4px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: $dark;
$hamburger-layer-border-radius: 3px;
$hamburger-hover-opacity: 0.7;
// $hamburger-types: (spin);

@import 'hamburgers';