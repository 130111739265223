body.page {
  section.header-slider {
    .header-image {
      @include dynamic-background-preconfig();
      @include flexbox-container();

      position: relative;
      width: 100%;
      height: 500px;
      max-height: 100vh;

      & > .neos-contentcollection {
        @include general-headline-styling;
        
        min-width: 100px;    
        position: relative;
        z-index: 1;
      }
    }
  }

  article.main-content {

  }
}