// Breakpoints
$screen-xs-min: 425px !default;
$screen-sm-min: 768px !default;
$screen-md-min: 992px !default;
$screen-lg-min: 1200px !default;
$screen-xs-max: $screen-xs-min - 1 !default;
$screen-sm-max: $screen-sm-min - 1 !default;
$screen-md-max: $screen-md-min - 1 !default;
$screen-lg-max: $screen-lg-min - 1 !default;

// Colors
$red: #ef1d26;
$dark: #2a2a2a;
$medium-dark: #535352;
$light: #e2e2e2;

// Shades
$shadow: #a6a6a6;
$black: #000;
$white: #fff;
$overlay: rgba($black, 0.85);

$red-darkened: darken($red, 5%);
$dark-darkened: darken($dark, 5%);
$light-darkened: darken($light, 5%);

$red-lightened: lighten($red, 5%);
$dark-lightened: lighten($dark, 5%);
$light-lightened: lighten($light, 5%);

// Set css variables
:root {
  --red: $red;
  --dark: $dark;
  --medium: $medium-dark;
  --light: $light;
  --shadow: $shadow;
}