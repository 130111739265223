.neos-nodetypes-text {
  &.color-dark {
    color: $dark;
  }

  &.color-light {
    color: $light;
  }
  
  &.color-red {
    color: $red;
  }
}