footer {
  .map-container {
    .map-wrapper {
      position: relative;

      .google-map-canvas {
        width: 100%;
        height: 300px;
      }
    }
  }

  .footer-wrapper {
    background: $light;
    padding: 30px 0;

    .contact {
      .obis-site-footer-information {
        display: block;
        padding: 1px;

        .footer-title {
          font-weight: bold;
          margin-bottom: 25px;
          margin-top: 0;
        }
      }
    }

    .red-bar {
      width: 100%;
      height: 25px;
      background: $red;
      margin-top: 30px;
    }

    .meta {
      font-size: 9pt;
      
      .copyright-notice {
        padding: 5px;
        display: block;    
      }
    }
  }
}