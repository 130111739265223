.obis-site-footer-menu {
  text-align: right;
  
  .footer-menu-item {
    display: inline-block;
    margin: 0 5px;

    .footer-menu-link {
      display: inline-block;
      color: $text-color;
      padding: 5px;
      text-decoration: underline;

      .footer-menu-label {

      }
    }
  }
}