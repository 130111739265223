button.obis-site-button {
  background-color: $light;
  border-color: $light;
  border-radius: 20px;
  border: 1px solid;
  color: $light;
  font-weight: bold;
  min-width: 120px;
  padding: 3px;
  text-align: center;
  outline: none !important;
  display: block;

  &.color-light {
    background-color: $light;
    border-color: $light;
    color: $dark;
  }

  &.color-dark {
    background-color: $dark;
    border-color: $dark;
    color: $light;
  }

  &.color-red {
    background-color: $red;
    border-color: $red;
    color: $dark;
  }

  &.align-center,
  &.align-justify {
    margin: 0 auto;
  }

  &.align-right {
    margin-left: auto;
  }
}