.google-map-canvas {
    &.smart-google-map {
        &+.contact-box-wrapper {
            position: absolute;
            z-index: 10;
            top: 20%;
            left: 10%;
            right: 50%;
            bottom: 15%;
            @media (max-width: $screen-sm-max) {
                position: relative;
                top: unset;
                left: unset;
                right: unset;
                bottom: unset;
                margin-bottom: 10px;
            }
            &>* {
                background: $medium-dark;
                color: $light;
            }
            .contact-box-title {
                display: inline-block;
                @media (max-width: $screen-sm-max) {
                    display: block;
                    margin-top: 10px;
                }
                margin-bottom: 10px;
                padding: 5px 25px;
                height: 45px;
                &>p {
                    display: inline;
                    margin: 0;
                    font-size: 18pt;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
            .contact-box {
                padding: 10px 10px 25px;
                height: calc(100% - 55px);
                .informational-slider {
                    .contact-information-slide {
                        .content-wrapper {
                            .content {
                                h4 {
                                    padding-left: 10px;
                                    max-width: 80%;
                                    font-size: 11pt;
                                    font-weight: 600;
                                    margin-bottom: 14px;
                                }
                                .flex-wrapper {
                                    display: flex;
                                    align-items: center;
                                    justify-content: left;
                                    i.glyphicons {
                                        width: 30px;
                                        font-size: 12pt;
                                        margin-right: 5px;
                                    }
                                }
                                .address,
                                .phone,
                                .fax,
                                .mail {
                                    margin-bottom: 5px;
                                }
                            }
                        }
                    }
                }
            }
            &+.map-overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 5;
            }
        }
    }
}