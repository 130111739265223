/* Mobile App Overlay */

.mobileLayerHidden {visibility: hidden;}
.mobileLayerVisible {visibility: visible;}

.mobileAppOverlay {
  display: none;
  z-index: 10;
  position: absolute;
  height: auto;
  background-color: $red;
  opacity: 0.8;

  @media (min-width: $screen-sm-min) {
    display: block;
    padding: 10px 10px 10px 10px;
    right: 3%;
    width: 350px;
    top: 8%;

    h2 {
      text-align: center;
      margin: 5px 2px 5px 2px;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
    }

    h3 {
      display: none;
      color: #ffffff;
      //font-size: 12px;
      //text-align: center;
    }
  }


  @media (min-width: $screen-md-min) {
    display: block;
    padding: 5px 5px 5px 5px;
    right: 3%;
    width: 160px;
    top: 8%;

    h2 {
      text-align: center;
      margin: 5px 2px 5px 2px;
      font-size: 18px;
      font-weight: 600;
    }

    h3 {
      display: block;
      font-size: 15px;
      text-align: center;
    }
  }

  @media (min-width: $screen-lg-min) {
    display: block;
    padding: 10px 10px 10px 10px;
    right: 8%;
    width: 200px;
    top: 12%;

    h2 {
      text-align: center;
      margin: 5px 2px 5px 2px;
      font-size: 22px;
      font-weight: 600;
    }

    h3 {
      display: block;
      font-size: 18px;
      text-align: center;
    }
  }



}
