section.content-section {
  padding: 35px 10px;

  &.color-light {
    color: $dark;
    background-color: $light;
  }

  &.color-dark {
    color: $light;
    background-color: $dark;
  }

  &.color-red {
    color: $light;
    background-color: $red;
  }
}