@mixin fontawesome($content:'') {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: $content;
}

@mixin _glyphicon($content) {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: $content;
}

@mixin glyphicons($content:'') {
    font-family: 'Glyphicons Regular';
    vertical-align: top;
    @include _glyphicon($content);
}

@mixin glyph-fileicons($content:'') {
    font-family: 'Glyphicons Filetypes';
    position: relative;
    top: 1px;
    @include _glyphicon($content);
}

@mixin glyph-halflings($content:'') {
    font-family: 'Glyphicons Halflings';
    position: relative;
    top: 1px;
    @include _glyphicon($content);
}

@mixin glyph-social($content:'') {
    font-family: 'Glyphicons Social';
    position: relative;
    top: 1px;
    @include _glyphicon($content);
}

@mixin clearfix {
    &::before,
    &::after {
        content: " ";
        display: table;
    }
    &::after {
        clear: both;
    }
}

@mixin custom-clearfix {
    display: block;
    clear: both;
    content: " ";
}

@mixin dynamic-background-preconfig {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

@mixin fake-column($size, $align: left) {
    // position: relative;
    width: 100% / 12 * $size;
    float: $align;
    min-height: 1px;
}

@mixin slide-height-mixin {
    min-height: 600px;
    max-height: 100vh;
    height: 70vh;
    overflow: hidden;
}

@mixin flexbox-container {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

@mixin flexbox-stretch-container {
    display: flex;
    align-content: stretch;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
}

@mixin general-headline-styling {
    h1 {
        font-size: 6em;
        font-weight: 800;
        margin: 10px 0;
    }
    h2 {
        font-size: 5em;
        font-weight: 700;
        margin: 10px 0;
    }
    h3 {
        font-size: 4em;
        font-weight: 600;
        margin: 5px 0;
    }
    h4 {
        font-size: 3em;
        font-weight: 300;
        margin: 5px 0;
    }
    h5 {
        font-size: 2em;
        font-weight: 300;
        margin: 5px 0;
    }
}