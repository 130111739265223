.panel-group{
    .panel{
        background-color: white;
        border: none;
        box-shadow: none;
        color: #000000!important;
        p{
            color: #000000 !important;
        }
        border-radius: 0px;
        .panel-heading{
            border: none;
            border-radius: 0px;
            background-color: #ef1d26;
            text-transform: uppercase;
            padding-top: 15px;
            padding-bottom: 15px;
            position: relative;
            .panel-title{
                padding-right: 30px;
            }
            span{
                font-weight: bold;
                color: #ffffff;
            }
            a:hover, a:active, a:focus{
                text-decoration: none;
            }
            a.collapsed::after, a::after{
                z-index: 10;
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                content: "\f150";
                display: inline-block;
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: absolute;
                font-size: 20px;
                color: #ffffff;
                right: 15px;
                top: calc(50% + 0.5px);
                transform: translateY(-50%) rotate(0deg);
                transform-style: preserve-3d;
            }
            a.collapsed::before{
                transform: translateY(-50%) rotate(-90deg);
                transition: all 0.25s ease-in-out;
                top: 50%;
            }
            a::before{
                transform-style: preserve-3d;
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                content: "\f151";
                display: inline-block;
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: absolute;
                font-size: 20px;
                color: #ffffff;
                right: 14.5px;
                top: calc(50% + 0.5px);
                transform: translateY(-50%) rotate(0deg);
                transition: all 0.25s ease-in-out;
                z-index: 10;
            }
        }
        .panel-body {
          .neos-contentcollection {
            a, a:hover, a:active, a:focus {
              color: #282e40;
              text-decoration: underline;
            }

            .neos-nodetypes-text {
              ul li::before {
                color: #ffe200;
              }
            }

          }

        }
    }
}
