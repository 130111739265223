nav.obis-site-mobilemenu {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;

  @include flexbox-container;
  
  background-color: rgba(255,255,255,0.7);
  z-index: 50;

  ul.nav.navbar-nav {
    color: $dark;
    font-size: 18pt;
    font-weight: 600;
    margin: 0;
    // text-align: center;

    li.menu-item {
      font-weight: bold;
      line-height: 25px;
      margin: 10px 0px;

      a.menu-link {
        cursor: pointer;
        display: inline-block;
        background-color: transparent;
        padding: 3px 7px;
        text-transform: uppercase;

        color: $dark;
        transition: color 0.3s ease;

        &:hover {
          color: $red;
        }
      }

      ul.submenu {
        list-style: none;
        padding: 0;
        overflow: hidden;
        margin-top: 5px;

        li.menu-item {
          font-weight: normal;
          margin: 0;
        }
      }

      .expander {
        cursor: pointer;
        display: inline-block;
        font-size: 130%;
        line-height: 25px;
        width: 18px;
        text-align: center;
        vertical-align: top;
      }
    }
  }
}