@font-face {
  font-family: 'Open Sans';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Open_Sans/OpenSans-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
 
@font-face {
  font-family: 'Open Sans';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Open_Sans/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
 
@font-face {
  font-family: 'Open Sans';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Open_Sans/OpenSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Open_Sans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Open_Sans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Open_Sans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/_Resources/Static/Packages/Obis.Site/Fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
