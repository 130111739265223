a.obis-site-iconlink {
  font-size: inherit;
  color: inherit;
  margin: 0 2px;
  padding: 3px;
  
  & > span {
    display: inline-block;
    line-height: 130%;
    vertical-align: middle;
    
    .icon-box {
      font-size: 130%;
      line-height: 1.2em;
      margin-right: 5px;
      vertical-align: text-top;
    }

    .inner-text {
      text-decoration: underline;
      font-weight: 600;
    }
  }
}