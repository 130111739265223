header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  
  padding: 15px;
  z-index: 999;

  background: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 99%);
  
  .header-inner {
    .header-inner-left-block {
      @include fake-column(8);

      @media (min-width: $screen-sm-min) {
        @include fake-column(4);
      }
  
      .home-link {
        display: block;
        max-width: 300px;
      }
    }
  
    .header-inner-right-block {
      @include fake-column(8);

      @media (max-width: $screen-sm-max) {
        display: none;
      }
  
      & > * {
        display: block;
        float: right;
      }
  
      .search-box {
        height: 50px;
        padding: 15px;
        color: white;
      }
  
      .obis-site-mainmenu {
        padding: 11px 0;
      }

      .obis-site-searchbox + .obis-site-mainmenu {
        transition: max-width 0.3s ease;
        max-width: 100%;
        overflow: hidden;
      }

      .obis-site-searchbox.active + .obis-site-mainmenu {
        max-width: 0%;
        max-height: 45px;
    
        // white-space: nowrap;
      }
    }

    .header-inner-right-block-mobile {
      @include fake-column(4);

      @media (min-width: $screen-sm-min) {
        display: none;
      }

      button.hamburger {
        position: absolute;
        right: 20px;
        z-index: 999;

        .hamburger-inner, 
        .hamburger-inner::after, 
        .hamburger-inner::before {
          background-color: $light;
        } 

        &.is-active {
          .hamburger-inner, 
          .hamburger-inner::after, 
          .hamburger-inner::before {
            background-color: $dark;
          }
        }
      }
    }
  }
}