nav.obis-site-mainmenu {
  color: $white;

  ul.nav.navbar-nav {
    margin-right: 0;

    li.menu-item {
      cursor: pointer;
      font-weight: bold;
      line-height: 25px;
      margin: 0 2px;

      a.menu-link {
        background-color: transparent;
        padding: 3px 7px;
        text-transform: uppercase;
        transition: background-color 200ms ease-in-out;
        display: block;

        &:hover {
          background-color: $red;
          color: $light;
        }
      }

      ul.submenu {
        background-color: #8a8a8a;
        list-style: none;
        padding: 0;
        position: fixed;

        li.menu-item {
          font-weight: normal;
          margin: 0;

          ul.submenu {
            background-color: #8a8a8a;
            list-style: none;
            padding: 0;
            overflow: hidden;
            position: absolute;
            visibility: hidden;
            left: 100%;
            margin-top: -31px;

            li.menu-item {
              font-weight: normal;
              margin: 0;
              white-space: nowrap;
            }
          }
        }
      }
    }

    li.major-menu-item:last-child {
      direction: rtl;
      text-align: right;
    }
  }
}