.obis-site-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: $dark;
  z-index: 0;

  &.darker {
    background-color: $black;
  }

  &.color-red, &.color-light, &.color-dark {
    transition: background-color 0.3s ease-in-out;
  }

  &:not(.clickable) {
    pointer-events: none;
  }
}

*:hover > .obis-site-overlay {
  &.color-red {
    background-color: $red;
  } 

  &.color-light {
    background-color: $light;
  }

  &.color-dark {
    background-color: $dark;
  }
}