hr.obis-site-divider {
  margin-top: 20px;
  margin-bottom: 20px;

  &.color-red {
    border-color: $red;
  }

  &.color-dark {
    border-color: $dark;
  }

  &.color-light {
    border-color: $light;
  }

  &.big-gap {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.neos-backend {
  hr.obis-site-divider {
    min-height: 5px;

    &.color-red {
      background: $red;
    }
  
    &.color-dark {
      background: $dark;
    }
  
    &.color-light {
      background: $light;
    }
  }
}